// @flow strict
import React from "react";
import { Link } from "gatsby";
import styles from "./ResumeEntry.module.scss";
import type { Node } from "../../types";
import moment from "moment";

type Props = {
  entry: Node,
};

const ResumeEntry = ({ entry }: Props) => {
  const { html } = entry;
  const { tagSlugs, slug } = entry.fields;
  const { company, from, to, position, location, website } = entry.frontmatter;

  return (
    <div className={styles["resume-entry"]}>
      <div className={styles["resume-entry__side"]}>
        <h3>{company}</h3>
        <span>{moment(from).format("MMM YYYY")}</span>
        {to !== null && <span> - {moment(to).format("MMM YYYY")}</span>}
        {to === null && <span> - Current</span>}
      </div>
      <div className={styles["resume-entry__main"]}>
        {position !== null && <h4>{position}</h4>}
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
        <div className={styles["resume-entry__main-meta"]}>
          {location}{" "}
          {website !== null && (
            <>
              | <a href={website}>Visit Website</a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeEntry;

// @flow strict
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Feed from "../components/Feed";
import Page from "../components/Page";
import Pagination from "../components/Pagination";
import { useSiteMetadata } from "../hooks";
import type { PageContext, AllMarkdownRemark } from "../types";
import Copyright from "../components/Copyright";
import moment from "moment";
import ResumeEntry from "../components/ResumeEntry";

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext,
};

const ResumeTemplate = ({ data, pageContext }: Props) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    copyright,
  } = useSiteMetadata();

  const {
    category,
    currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  const { edges } = data.allMarkdownRemark;
  const pageTitle = `Résumé - ${siteTitle}`;

  return (
    <>
      <Layout title={pageTitle} description={siteSubtitle}>
        <Sidebar />
        <Page title="Résumé">
          <h2>Career</h2>
          {edges
            .filter((edge) => edge.node.frontmatter.type === "work")
            .map((edge) => (
              <ResumeEntry entry={edge.node}></ResumeEntry>
            ))}

          <h2>Education</h2>
          {edges
            .filter((edge) => edge.node.frontmatter.type === "education")
            .map((edge) => (
              <ResumeEntry entry={edge.node}></ResumeEntry>
            ))}
        </Page>
      </Layout>
      <Copyright copyright={copyright} />
    </>
  );
};

export const query = graphql`
  query ResumeTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "resume" } } }
      sort: { order: DESC, fields: [frontmatter___from] }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            position
            location
            from
            to
            company
            website
            type
          }
        }
      }
    }
  }
`;

export default ResumeTemplate;
